.reset-options {
  display: flex;
  width: 90%;
  padding: 20px 15px;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  .confirmation {
    background-color: #f5222d;
    color: #ffffff;

    .anticon {
      color: #ffffff; } } }
