.login {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  .login-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background: white;
    width: 100%;
    max-width: 300px;
    padding: 50px;

    h2 {
      text-transform: uppercase;
      margin-bottom: 40px;
      text-align: center; }

    img {
      width: 100px; }

    p {
      text-align: center;
      color: #f98178;
      padding: 10px 0 0;
      margin-bottom: 0; } } }
