.config-loading {
  width: 100%; }

.config-form {
  width: 100%;
  max-width: 900px;
  margin: auto;

  .form-submit {
    text-align: right; }

  .ant-form-item-required {
    display: flex;
    white-space: normal;

    &:before {
      display: none; } } }
