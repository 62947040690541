.coupons-products {
  .ant-transfer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    .ant-transfer-list {
      width: calc(50% - 30px);
      height: 500px; } } }

.ant-table {
  td.code {
    font-family: monospace;
    font-size: .9rem;
    letter-spacing: 0.6px; } }

.ant-table-footer {
  .coupons-button {
    padding-top: 0; } }

.coupons-button {
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a,
  button {
    margin-left: 10px; } }

.coupons-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;

  button {
    margin-left: 10px; } }
