.list {
  .list-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      display: inline-block;
      margin-right: 10px;
      font-style: italic;

      &.success {
        color: green; }

      &.error {
        color: red; } }

    .switch-active {
      margin-right: 15px; } }

  .ant-list-item-content {
    flex: initial;
    width: 60%; } }
