$primary: #1890ff;
$bg: #f7f8f9;
$hr: #e8e8e8;

body {
  background: $bg; }

.ant-layout {
  background: transparent; }

.ant-menu-horizontal {
  border-bottom: 0; }

.centered {
  max-width: 1200px;
  width: 100%;
  margin: auto; }

.c-header-wrapper {
  background: #fff;
  width: 100%;
  border-bottom: 1px solid $hr; }

.c-header {
  padding: 0 20px;
  @extend .centered;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  .c-header-left {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center; }

  .c-dropdown {
    color: $primary;
    cursor: pointer;
    margin-left: 20px; }

  .c-menu-right {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center; } }

.c-logo {
  color: $primary;
  margin-right: 20px;
  width: 50px;

  img {
    width: 100%; } }

.c-content {
  padding: 20px;
  @extend .centered; }

.c-menu {
  .ant-menu-item {
    .anticon {
      margin-right: 0; } } }

.ant-card-body {
  .list {
    .ant-list-item {
      padding: 8px;

      .ant-list-item-meta-title {
        margin: 0; } } } }
