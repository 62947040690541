.ant-table-wrapper {
  margin-top: 20px; }

.confirm-migration {
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: center;
  align-items: center;

  .message {
    font-size: 15px;
    width: 60%;
    text-align: center;

    .bold {
      font-weight: bold; } } }
