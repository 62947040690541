.add-action {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .switch {
    display: flex;

    span {
      order: 2; }

    .ant-switch {
      order: 1;
      margin-right: 5px; } } }

.validate-errors {
  color: red;
  font-size: 0.7rem; }

.list {
  .ant-badge-status-dot {
    transform: scale(1.5); } }

.add-purchase {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .purchase-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    .nf {
      width: 30%;

      span {
        font-size: 15px; }

      .ant-input {
        margin-top: 10px; } }

    .type {
      width: 30%;

      span {
        font-size: 15px; }

      .ant-select {
        margin-top: 10px;
        width: 100%; } } }

  .list-products {
    width: 100%;
    margin-top: 15px;

    .title {
      font-size: 15px;
      margin-bottom: 5px;

      .ant-input-search {
        margin-top: 10px; } }

    .content {
      padding: 0 10px;
      overflow-y: auto;
      max-height: 400px; }

    .footer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end; } } }

.product-purchase {
  .product-purchase-content {
    max-height: 650px;
    overflow-y: auto;

    .view-product-purchase {
      .button {
        margin-bottom: 15px;
        width: 100%;
        text-align: right; }

      .ant-list-item {
        display: flex;
        flex-direction: row;

        .ant-list-item-meta-title {
          margin-bottom: 0; } } } } }

.confirm-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;

  .message {
    width: 70%;
    text-align: center;
    font-size: 1rem; }

  .buttons {
    margin-top: 30px;
    width: 80%;
    display: flex;
    justify-content: space-between; } }
